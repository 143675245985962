import { createHttpEndpoint } from '../../utils'

import type { CartResponse } from './cart.types'
import type { InsuranceAgreement } from './insuranceAgreement.types'
import type { ShippingsResponse } from './shippings.types'

/**
 * Retrieve cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/Cart}
 */
export const getCart = createHttpEndpoint<CartResponse>({
  method: 'GET',
  operationId: 'fetchCart',
  path: '/bm/cart',
  transformResponseToCamelCase: true,
})

/**
 * Add a listing to the cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartAddProduct}
 */
export const addToCart = createHttpEndpoint({
  method: 'POST',
  operationId: 'addToCart',
  path: '/bm/cart/add_product',
})

/**
 * Add bundle of listings to the cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartAddProducts}
 */
export const addProductsToCart = createHttpEndpoint({
  method: 'POST',
  operationId: 'addProductsToCart',
  path: '/bm/cart/add_products',
})

/**
 *
 * Add service to cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#/Checkout%20services/CartAddService}
 */
export const addServiceToCart = createHttpEndpoint({
  method: 'POST',
  operationId: 'addServiceToCart',
  path: '/bm/cart/services',
})

/**
 * Add a swap offer to the cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartSwapAdd}
 */
export const postSwap = createHttpEndpoint({
  method: 'POST',
  operationId: 'addSwapToCart',
  path: '/bm/cart/swap',
})

/**
 * Remove swap offer from the cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartSwapDelete}
 */
export const deleteSwap = createHttpEndpoint({
  method: 'DELETE',
  operationId: 'deleteSwap',
  path: '/bm/cart/swap',
})

/**
 * Update quantity of a listing in cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartUpdateQuantity}
 */
export const postUpdateQuantity = createHttpEndpoint({
  method: 'POST',
  operationId: 'updateQuantity',
  path: '/bm/api/update_quantity',
  transformRequestToSnakeCase: true,
})

/**
 * Update delivery option of a listing in cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartUpdateOption}
 */
export const postUpdateOption = createHttpEndpoint({
  method: 'POST',
  operationId: 'updateOption',
  path: '/bm/cart/update_option',
  transformRequestToSnakeCase: true,
})

/**
 * Retrieve available shipping options of all listings
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartShippingsGet}
 */
export const getShippings = createHttpEndpoint<ShippingsResponse[]>({
  method: 'GET',
  operationId: 'fetchCartShippings',
  path: '/bm/cart/shippings',
})

/**
 * Update selected shipping option for a listing.
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartShippingsPost}
 */
export const postShippings = createHttpEndpoint({
  method: 'POST',
  operationId: 'saveCartShippings',
  path: '/bm/cart/shippings',
})

/**
 * Update selected insurance offer
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartUpdateInsuranceOfferPost}
 */
export const postUpdateInsuranceOffer = createHttpEndpoint({
  method: 'POST',
  operationId: 'updateInsuranceOffer',
  path: '/bm/cart/update_insurance_offer',
  transformRequestToSnakeCase: true,
})

/**
 * Toggle insurance user agreement's state
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartAcceptAgreementPost}
 */
export const postAcceptAgreement = createHttpEndpoint({
  method: 'POST',
  operationId: 'accept_agreement',
  path: '/bm/cart/accept_agreement',
  transformRequestToSnakeCase: true,
})

export const getInsuranceAgreement = createHttpEndpoint<InsuranceAgreement>({
  method: 'GET',
  operationId: 'getInsuranceAgreement',
  path: '/bm/cart/insurances/agreement',
})

export const postInsuranceAgreement = createHttpEndpoint<InsuranceAgreement>({
  method: 'POST',
  operationId: 'getInsuranceAgreement',
  path: '/bm/cart/insurances/agreement',
})

/**
 * Update collection point in cart
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartUpdateCollectionPointPost}
 */
export const postUpdateCollectionPoint = createHttpEndpoint({
  method: 'POST',
  operationId: 'updateCartCollectionPoint',
  path: '/bm/cart/update-collection-point',
})

/**
 * Update collection point customer details
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartUpdateCollectionPointCustomerDetailsPost}
 */
export const postUpdateCollectionPointCustomerDetails = createHttpEndpoint({
  method: 'POST',
  operationId: 'updateCollectionPointCustomerDetails',
  path: '/bm/cart/update-collection-point-customer-details',
})

/**
 * Update user information
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#operation/CartUpdateUserInformation}
 */
export const postUpdateUserInformation = createHttpEndpoint({
  method: 'POST',
  operationId: 'updateUserInformation',
  path: '/bm/cart/update-user-information',
})

/**
 *
 * Delete a service from the cart
 *
 * https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition#/Checkout%20services/CartDeleteService
 */
export const deleteService = createHttpEndpoint({
  method: 'DELETE',
  operationId: 'deleteService',
  path: '/bm/cart/services',
})
